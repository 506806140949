import React from 'react';
import dexed_logo from '../../images/dexed-logo.svg';
import dexed_text from '../../images/dexed-text.svg';

const Header = () => {

    return (
        <header className="transparent">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="de-flex sm-pt10">
                            <div className="de-flex-col">
                                <div className="de-flex-col">
                                    <div id="logo">
                                        <a href="/public">
                                            <img className="logo-main" src={dexed_logo} alt="" />
                                            <img className="logo-main" src={dexed_text} alt="" />

                                            <img className="logo-mobile" src={dexed_logo} alt="" />
                                            <img className="logo-mobile" src={dexed_text} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="de-flex-col header-col-mid">
                                <ul id="mainmenu">
                                    <li><a className="menu-item" href="#features">Features</a></li>
                                    <li><a className="menu-item" href="#tokenomics">Tokenomics</a></li>
                                    <li><a className="menu-item" href="#benefit">Benefit</a></li>
                                    <li><a className="menu-item" href="#roadmap">Roadmap</a></li>
                                    <li><a className="menu-item" href="#buy">Buy</a></li>
                                    <li><a className="menu-item" href="#faq">FAQ</a></li>
                                </ul>
                            </div>
                            <div className="de-flex-col">
                                <div className="menu_side_area">
                                    <a href="https://x.com/DexedApp" target="_blank" className="btn-main btn-line"><span>X</span></a>
                                    <a href="https://t.me/dexed_app" target="_blank" className="btn-main btn-line"><span>TG</span></a>
                                    <a href="https://dexed.com/" className="btn-main btn-line"><span>LAUNCH APP</span></a>
                                    <span id="menu-btn"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )

}

export default Header;