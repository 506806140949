import React, { useEffect, useState } from "react";
import bg280586 from '../../images/background/280586.jpg';
import astrocool2 from '../../images/misc/astro-cool2.png';
import { mainColor, secondaryColor } from "../..";


const getTokenInfo = async () => {

    try {
        const data = await fetch("https://api.flooz.trade/v1/tokens/0xB6182b03d9AEA18b6b2A0e5E41d99F0F7f2E5EE9?network=eth&filteroutBlacklisted=false");
        const res = await data.json();
        return res;

    } catch(e) {
        console.log(e);
    }
}

let options = {
    notation: "compact",
    compactDisplay: "short",
};

// @ts-ignore
const usformatter = Intl.NumberFormat("en-US", options);


const Buy = () => {
    
    const [tokenData, setTokenData] = useState<any[]>([]);

    useEffect(() => {
        getTokenInfo().then(res => {
            console.log(res);
            setTokenData(res)
        })
    }, [])

    return (
        <section id="buy">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="padding60 sm-padding40 jarallax position-relative">
                            <img src={bg280586} className="jarallax-img" alt=""/>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="subtitle wow fadeInUp mb-3">Buy $DEXED now</div>
                                        <h2 className="wow fadeInUp" data-wow-delay=".2s">Join the community</h2>
                                        <div style={{marginLeft:"-30px"}}>
                                            <div className="de_count text-light wow fadeInUp" style={{width: "50%", float: "left"}}>
                                                <h3 className="timer">${usformatter.format(tokenData[0]?.marketCap)}</h3>
                                                <h4 className="text-uppercase">Marketcap</h4>
                                            </div>
                                            <div className="de_count text-light wow fadeInUp" style={{width: "50%", float: "left"}}>
                                                <h3 className="timer" id="mkcap" data-speed="2000">{tokenData[0]?.holders}</h3>
                                                <h4 className="text-uppercase">Holders</h4>
                                            </div>
                                        </div>
                                        <a className="btn-main btn-lg wow zoomIn"
                                            style={{background: `linear-gradient(to right, ${mainColor}, ${secondaryColor})`}}
                                           href="https://app.uniswap.org/#/swap?exactField=input&exactAmount=10&inputCurrency=0xB6182b03d9AEA18b6b2A0e5E41d99F0F7f2E5EE9">
                                            <span>Buy now Uniswap</span>
                                        </a>&nbsp;
                                        <a className="btn-main btn-lg wow zoomIn"
                                           href="https://dexed.com/eth/0x3Edab28eDB8ef8a1Bcb0e9211A81Ea95eFE6d0Cd"
                                           title="View $DEXED chart on Dexed.io"
                                           style={{width: "50px", background: `linear-gradient(to right, ${mainColor}, ${secondaryColor})`, marginLeft: 10}}>
                                            <i className="fa-solid fa-line-chart" style={{marginLeft: "-12px", marginTop:"-10px"}}></i>
                                        </a>
                                        <p>
                                            <span
                                                style={{fontStyle: "italic", fontWeight: 800, color: "white"}}>
                                                For Uniswap: Set slippage above 5%.
                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <img
                                    src={astrocool2}
                                    className="sm-hide position-absolute bottom-0 end-0 wow fadeIn"  alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Buy;
